import { Route, Routes } from 'react-router-dom';
import './App.css';
import Controller from './pages/controller/Controller';
import Login from './pages/Login/Login';
import SignUp from './pages/Signup/SignUp';
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <Routes>
      <Route path='/signup' element={<SignUp />} />
      <Route path='/controller' element={<Controller />} />
      <Route path='/' element={<Login />} />
    </Routes>
  );
}

export default App;
