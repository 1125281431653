import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import StopRoundedIcon from '@mui/icons-material/StopRounded';
import React, { useEffect, useState } from 'react'
import "./controller.css"
import { LoadingButton } from '@mui/lab';
import { Container, InputAdornment, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import AlertDialog from '../../components/common/AlertDialog';
import TimeDialog from '../../components/common/TimeDialog';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
const Controller = () => {
	const token = localStorage.getItem("token")
	const [loading, setLoading] = useState({ type: "", loading: false });
	const [data, setData] = useState({})
	const [pendingOrders, setPendingOrders] = useState([])
	const [initialAmount, setInitialAmount] = useState(null)
	const [alert, setAlert] = useState({
		type: "",
		open: false,
		headerTxt: "",
		description: ""
	})
	const [inputs, setInputs] = useState({})
	const [orderId, setOrderId] = useState("");
	const [open, setOpen] = useState(false)

	const handleChange = (e) => {
		const { name, value } = e.target

		setInputs({ ...inputs, [name]: value })
	}

	const handleClick = (type) => {
		if (type === "stop") {
			setAlert({
				type,
				open: true,
				headerTxt: "Stop bot",
				description: "Are you sure, you want to stop the crypto bot?"
			})
		} else {
			setLoading({ type, loading: true });
			
			const reqstData = {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					token
				},
				body: JSON.stringify({
					"initial_investment_in_USD": Number(initialAmount),
					"profit_per": Number(inputs.profit),
					"stop_loss_per": Number(inputs.stopLoss),
					"indicator_timeframe": inputs.timeFrame?.toString()
				})
			}

			let url = ""

			if (type === "start") {
				url = `${process.env.REACT_APP_API_URL}/start-bot`
			} else {
				url = `${process.env.REACT_APP_API_URL}/stop-bot`
			}
			fetch(url, reqstData)
				.then(res => res.json())
				.then(data => console.log(type === "start" ? "Bot started" : "Bot stopped"))
				.catch(err => console.log(err, "<<-- Error in start-bot api"))

			setLoading({ type: '', loading: false })
		}
	}

	const stop_bot = () => {
		setLoading({ type: "stop", loading: true });
		const reqstData = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				token
			}
		}
		fetch(`${process.env.REACT_APP_API_URL}/stop-bot`, reqstData)
			.then(res => res.json())
			.then(() => console.log("Bot stopped"))
			.catch(err => {
				console.log(err, "<<-- Error in start-bot api")
				alert("Failed to stop bot")
			})

		setLoading({ type: '', loading: false })
		setAlert({})
	}

	const getTUSDBalance = async () => {
		const reqstData = {
			method: "GET",
			headers: {
				"content-type": "application/json",
				token
			}
		}
		fetch(`${process.env.REACT_APP_API_URL}/get-balance?symbol=TUSD`, reqstData).then(res => res.json()).then(data => setData(data))
	}

	const getPendingOrder = () => {
		const reqstData = {
			method: "GET",
			headers: {
				"content-type": "application/json",
				token
			}
		}
		fetch(`${process.env.REACT_APP_API_URL}/get-pending-orders?symbol=BTC/TUSD`, reqstData)
			.then(res => res.json())
			.then(data => setPendingOrders(data.pending_orders))
			.catch(err => console.error(err, "<<-- Error in getPendingOrder"))
	}

	useEffect(() => {
		getTUSDBalance()
		getPendingOrder()
	}, [])

	const cancel_order = () => {
		const reqstData = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				token
			},
			body: JSON.stringify({
				id: Number(orderId),
				pair: "BTCTUSD"
			})
		}

		let url;

		if (alert.type === "cancel") {
			url = `${process.env.REACT_APP_API_URL}/cancel-pending-orders?symbol=BTC/TUSD`
		} else {
			url = `${process.env.REACT_APP_API_URL}/place-market-order`
		}

		fetch(url, reqstData)
			.then(res => res.json())
			.then(data => setPendingOrders(data.pending_orders))
			.catch(err => console.error(err, "<<-- Error in getPendingOrder"))
		setAlert({})
	}

	const handleClose = () => {
		setAlert({})
	}

	const handleRemove = (orderId, type) => {
		setOrderId(orderId)
		setAlert({
			type,
			open: true,
			headerTxt: type === "cancel" ? "Cancel Order" : "Market Sell",
			description: type === "cancel" ? "Are you sure, you want to cancel your order?" : "Are you sure, you want to sell this to market?"
		})
	}

	const handleTimeOpen = (e) => {
		setOpen(true)
	}

	const handleCloseTimeModal = () => {
		setOpen(false)
	}

	const handleTimeFrame = (value) => {
		setInputs({ ...inputs, timeFrame: value })
		setOpen(false)
	}

	return (
		<>
			{/* Header */}

			<div className="header">
				<div className="logo">BotUi</div>

				<Link href='/' variant='button' style={{ textDecoration: "none" }}>
					<div className="logout">Logout</div>
				</Link>
			</div>

			{/* Controller */}
			<div className='flex justify-center items-center'>
				<div className='container'>
					<div className='main_controller'>
						<div className='buttons-group'>
							<LoadingButton
								color="primary"
								onClick={() => handleClick("start")}
								loading={loading.type === "start" ? loading.loading : false}
								loadingPosition="start"
								startIcon={<PlayArrowRoundedIcon />}
								variant="contained"
								className='action-btn'
							>
								<span>Start</span>
							</LoadingButton>
							<LoadingButton
								color="primary"
								onClick={() => handleClick("stop")}
								loading={loading.type === "stop" ? loading.loading : false}
								loadingPosition="start"
								startIcon={<StopRoundedIcon />}
								variant="contained"
								className='action-btn'
							>
								<span>Stop</span>
							</LoadingButton>
							{/* <LoadingButton
							color="primary"
							onClick={handleClick}
							loading={loading}
							loadingPosition="start"
							startIcon={<ClearAllRoundedIcon />}
							variant="contained"
							className='action-btn'
						>
							<span>Clear all pending</span>
						</LoadingButton> */}
						</div>

						<div className='controller'>
							<div className='rows'>
								<div className="item">TUSD Balance</div>
								<div className="item">{data?.balance?.total ? `$${data?.balance?.total.toFixed(2)}` : ""}</div>
							</div>

							{/* <div className='rows'>
							<div className="item">Set cancel buy order time</div>
							<div className="item">
								<TextField id="outlined-basic" variant="outlined" />
							</div>
						</div>

						<div className='rows'>
							<div className="item">Set cancel sell order time</div>
							<div className="item">
								<TextField id="outlined-basic" variant="outlined" />
							</div>
						</div> */}
							<div className='flex justify-between items-center place-content-center'>
								<div>
									<div className='rows'>
										<div className="item">Set initial amount</div>
										<div className="item">
											<TextField
												type={"number"}
												className='w-[80%]'
												placeholder='Enter initial amount'
												id="outlined-basic"
												variant="outlined"
												value={initialAmount}
												onChange={(e) => {
													setInitialAmount(e.target.value)
												}}
												InputProps={{
													startAdornment: <InputAdornment position='start' className='text-black'>$</InputAdornment>
												}}
											/>
										</div>
									</div>
									<div className='rows'>
										<div className="item">Profit Percentage</div>
										<div className="item">
											<TextField
												type={"number"}
												className='w-[80%]'
												placeholder='Enter profit percentage'
												name='profit'
												id="outlined-basic"
												variant="outlined"
												value={inputs.profit}
												onChange={handleChange}
												InputProps={{
													endAdornment: <InputAdornment position='end' className='text-black'>%</InputAdornment>
												}}
											/>
										</div>
									</div>
								</div>
								<div>
									<div className='rows'>
										<div className="item">Stop-Loss Percentage</div>
										<div className="item">
											<TextField
												type={"number"}
												className='w-[80%]'
												placeholder='Enter stop loss percentage'
												name='stopLoss'
												id="outlined-basic"
												variant="outlined"
												value={inputs.stopLoss}
												onChange={handleChange}
												InputProps={{
													endAdornment: <InputAdornment position='end' className='text-black'>%</InputAdornment>
												}}
											/>
										</div>
									</div>
									<div className='rows'>
										<div className="item">Indicator Timeframe</div>
										<div className="item" onClick={handleTimeOpen}>
											<div className='w-[80%] flex items-center border border-[#c4c4c4] h-14 rounded relative' >
												<p className={`${!inputs.timeFrame ? "text-[#b6b6b6]" : "text[#3e3e3e]"} font-normal text-base ml-4`}>{inputs.timeFrame || "Select time frame"}</p>
												<ArrowDropDownIcon className='absolute right-2 text-[#696969]' />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
					<div className="pending_order">
						<TableContainer component={Paper} style={{ tableLayout: "auto" }}>
							<Table sx={{ minWidth: 650 }} aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell>Symbol</TableCell>
										<TableCell>OrderId</TableCell>
										<TableCell>Price</TableCell>
										<TableCell>Side</TableCell>
										{/* <TableCell>Action</TableCell> */}
									</TableRow>
								</TableHead>
								<TableBody>
									{pendingOrders && pendingOrders.length > 0 && pendingOrders?.map((row) => (
										<TableRow
											key={row.id}
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
										>
											<TableCell>{row?.info?.symbol}</TableCell>
											<TableCell>{row?.info?.orderId}</TableCell>
											<TableCell>{Number(row?.info?.price)?.toFixed(2)}</TableCell>
											<TableCell>{row?.info?.side}</TableCell>
											{/* <TableCell style={{ width: "14rem" }}>
												<div className='action_buttons'>
													<div className="delete_order" onClick={() => handleRemove(row.id, "cancel")}>Cancel</div>
													{row?.info?.side === "SELL" && <div className="delete_order market_sell__btn" onClick={() => handleRemove(row.id, "sell")}>Market Sell</div>}
												</div>
											</TableCell> */}
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</div>
				</div >
			</div>
			{/* Confirmation modal for cancel order and market sell */}
			<AlertDialog open={alert.open} headerTxt={alert.headerTxt} description={alert.description} cancel={handleClose} delete={alert.type === "stop" ? stop_bot : cancel_order} />
			<TimeDialog open={open} onClose={handleCloseTimeModal} handleTimeFrame={handleTimeFrame} />
		</>
	)
}

export default Controller