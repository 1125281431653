import { Dialog, DialogContent, DialogTitle } from "@mui/material";

export default function TimeDialog(props) {
	const timeArr = ["1s", "1m", "3m", "5m", "15m", "30m", "1H", "2H", "4H", "6H", "8H", "12H", "1D", "3D", "1W", "1M"]
	return (
		<div className="w-full">
			<Dialog
				className="w-full"
				open={props.open}
				onClose={props.onClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					Select Timeframe
				</DialogTitle>
				<DialogContent className="flex justify-center items-start">
					<div className="flex justify-start items-center w-3/4 flex-wrap">
						{timeArr.map((time, idx) => {
							return (
								<div className="w-20 h-10 bg-orange-400 flex items-center gap-5 mr-5 mt-5 cursor-pointer rounded" key={idx} onClick={() => props.handleTimeFrame(time)}>
									<p className="w-full text-center text-white">{time}</p>
								</div>
							)
						})}
					</div>
				</DialogContent>
			</Dialog>
		</div>
	)
}